.singleartwrapper {
    overflow: hidden;
  }
  
  .singleartimg {
    cursor: zoom-in;
  }
  
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .modal-img {
    max-width: 100%;
    max-height: 100%;
    border:  2px solid black;
  }

  .modal {
    background-color: rgba(0, 0, 0, 0.395); /* Adjust the alpha (fourth parameter) for transparency */
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Same color as .modal background */
    display:flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
  }

  .modal-content:focus-visible {
    border: none;
    outline: none !important;
  }
  
  .close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    z-index: 10000;
  }