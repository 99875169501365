@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}


.slide__right {
  animation: 1s ease-out 0s 1 slideInRight;
}

.slide__left {
  animation: 1s ease-out 0s 1 slideInLeft;
}

.slide__top {
  animation: 1s ease-out 0s 1 slideInTop;
}

.slide__bottom {
  animation: 1s ease-out 0s 1 slideInBottom;
}


@keyframes turn-on {
  0% {
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 1;
    transform: scaleY(.8) translateZ(0);
    -webkit-transform: scaleY(.8) translateZ(0);
  }

  3.5% {
    transform: scaleY(.8) translate3d(0, 100%, 0);
    -webkit-transform: scaleY(.8) translate3d(0, 100%, 0);
  }

  3.6% {
    opacity: 1;
    transform: scaleY(.8) translate3d(0, -100%, 0);
    -webkit-transform: scaleY(.8) translate3d(0, -100%, 0);
  }

  9% {
    filter: brightness(30);
    -webkit-filter: brightness(30);
    opacity: 0;
    transform: scale(1.3, .6) translate3d(0, 100%, 0);
    -webkit-transform: scale(1.3, .6) translate3d(0, 100%, 0);
  }

  11% {
    filter: contrast(0) brightness(0) saturate(1);
    -webkit-filter: contrast(0) brightness(0) saturate(1);
    opacity: 0;
    transform: scale(1) translateZ(0);
    -webkit-transform: scale(1) translateZ(0);
  }

  100% {
    filter: contrast(1.075) brightness(1.14) saturate(1.3);
    -webkit-filter: contrast(1.075) brightness(1.14) saturate(1.3);
    opacity: 1;
    transform: scale(1) translateZ(0);
    -webkit-transform: scale(1) translateZ(0);
  }
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

.art__title {
  background-color: whitesmoke;
  font-size: 18vw;
  color: rgba(43, 43, 43, 0.63);
  margin: 4px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.art__title:hover {
  cursor: pointer;
  transform: scale(1.03);
  color: rgb(38, 38, 38);
  text-shadow: 0 0 10px #ffea00, 0 0 20px #ffab02;
}

a.art__title {
  color: rgba(84, 84, 84, 0.97);
  font-weight: 800;
  transition: all 0.2s ease-in-out;
}

body {
  margin: 0;
  font-family: 'Segoe UI', Arial, Helvetica, Sans Serif;
}

a {
  text-decoration: none;
  display: inline-block;
}

a.header__name {
  color: rgba(84, 84, 84, 0.97);
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

body {
  margin: 0;
  padding: 0;
  font-family: Raleway;
  font-weight: bolder;
  color: rgb(46, 45, 49);
}

body * {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');

.artgrid {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: whitesmoke;
}

.artgriditem {
  width: 21rem;
  flex-grow: 1;
  border: 1px solid rgba(84, 84, 84, 0.5);
  margin: 10px;
  cursor: zoom-in;
}

.art__img {
  width: 21rem;
  flex-grow: 1;
  margin: 10px;
}

@media (max-width: 600px) {
  .art__img {
    width: unset;
  }
}



.image-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image-container.loaded {
  opacity: 1;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

.art__img:hover {
  transform: scale(1.02) !important;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.artwrapper {
  padding-top: 30px;
  background: rgb(46, 45, 49);
}



html {
  scroll-behavior: smooth;
}




@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.singleartwrapper {
  display: flex;
  justify-content: center;
}

.titlewrapper {
  width: 100vw;
  background-color: rgb(30, 30, 31);
  text-align: center;
}
